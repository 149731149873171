<template>
    <v-card class="mt-3 w-75 mx-auto">
        <v-toolbar>
            <v-toolbar-title>
                {{ getRegionName }}
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <div v-if="clientsList && clientsList.length > 0">
                <v-data-table
                    :headers="headers"
                    :items="sortedClientsList"
                    :hover="true"
                    class="elevation-1"
                >
                    <template v-slot:[`item.index`]="{ index }">
                        {{ index + 1 }}
                    </template>
                    <template v-slot:[`item.first_name`]="{ item }">
                        {{
                            `${item.raw.first_name}, ${item.raw.last_name ? item.raw.last_name.charAt(0) + '.' : ''}`
                        }}
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip :color="getStatusColor(item.raw.status)" variant="flat">
                            {{ getStatusTitle(item.raw.status) }}
                        </v-chip>
                    </template>
                    <template v-slot:bottom> </template>
                </v-data-table>
            </div>
            <div v-else class="mt-5 d-flex justify-content-center">
                <h6>Nu există date disponibile.</h6>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import apiClient from "@/utils/apiClient";
import {VDataTable} from "vuetify/labs/components";

export default {
    name: "GreenHouseClientsListView",
    components: {
        VDataTable
    },
    data() {
        return {
            selectedRegion: 40,
            availableRegions: [],
            clientsList: [],
            availableStatusOptions: [
                {title: 'În așteptare', value: 1},
                {title: 'Finalizat', value: 2},
            ],
            headers: [
                {title: 'Nr. crt.', key: 'index', sortable: false, width: '50px', align: 'end'},
                {title: 'Număr contract', key: 'contract_number', sortable: true, width: '50px', align: 'end'},
                {title: '', key: '', sortable: false, width: '75px'},
                {title: 'Nume', key: 'first_name', sortable: true},
                {title: 'Localitate', key: 'city', sortable: true},
                {title: 'Status', key: 'status', sortable: true},
            ],
        };
    },
    computed: {
        getRegionName() {
            let region = this.availableRegions.find(region => region.value === this.selectedRegion);
            return region ? region.title : 'Necunoscut';
        },
        sortedClientsList() {
            return [...this.clientsList].sort((a, b) => a.contract_number - b.contract_number);
        },
    },
    mounted() {
        fetch("/cities.json")
            .then((response) => response.json())
            .then((data) => {
                this.availableRegions = data
                    .filter((item) => item.model === "cities_light.region")
                    .sort((a, b) => a.fields.display_name.localeCompare(b.fields.display_name))
                    .map((item) => ({
                        title: item.fields.display_name,
                        value: item.pk,
                    }));
            });
        this.fetchClientsList(); // Fetch list when component is mounted
    },
    methods: {
        // Get status title based on value
        getStatusTitle(status) {
            const statusOption = this.availableStatusOptions.find(option => option.value === status);
            return statusOption ? statusOption.title : '';
        },
        // Get chip color based on status
        getStatusColor(status) {
            switch (status) {
                case 1:
                    return 'yellow'; // În așteptare
                case 2:
                    return 'green'; // Finalizat
                case 3:
                    return 'red'; // Respins
                case 4:
                    return 'blue'; // În prelucrare
                default:
                    return 'grey'; // Default color if status is not found
            }
        },
        async fetchClientsList() {
            const userId = sessionStorage.getItem("userId");
            const isClientUser = sessionStorage.getItem("isClientUser") === 'true';  // Check if the logged-in user is a client

            if (!userId) {
                alert("User not logged in!");
                return;
            }

            let apiUrl = `/api/get_clients_list/?user_id=${userId}&is_client_user=${isClientUser}`;

            // Only include region in the API call if the user is not a client user
            if (!isClientUser && this.selectedRegion) {
                apiUrl += `&region=${this.selectedRegion}`;
            }

            try {
                const response = await apiClient.get(apiUrl);

                if (response) {
                    this.clientsList = response.data.clients;
                    this.selectedRegion = parseInt(response.data.region);
                } else {
                    this.clientsList = [];
                }
            } catch (error) {
                alert("An error occurred while fetching the list.");
            }
        },
    },
};
</script>
