<template>
    <v-card class="mt-3">
        <v-toolbar>
            <v-toolbar-title>
                Clienți casă verde
            </v-toolbar-title>
            <v-toolbar-items>
                <v-btn
                    color="primary"
                    @click="saveClientsList"
                >
                    Salvează lista
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-row>
                <v-col cols="6">
                    <v-autocomplete
                        clearable
                        label="Județ"
                        :items="formattedRegions"
                        v-model="selectedRegion"
                        :variant="this.$store.state.vuetifyFieldType"
                        item-title="title"
                        item-value="value"
                        autocomplete
                        hide-details
                        density="compact"
                        @update:modelValue="fetchClientsList"
                    >
                        <template v-slot:item="{ props, item }">
                            <v-list-item
                                v-bind="props"
                                :append-icon="item.raw.icon"
                            >
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="6">
                    <div v-if="listUser.email && listUser.password">
                        <div class="d-flex flex-row gap-3">
                            <v-text-field
                                label="Username"
                                v-model="listUser.email"
                                variant="outlined"
                                hide-details
                                density="compact"
                                readonly
                                append-inner-icon="mdi-content-copy"
                                @click:append-inner="copyToClipboard(listUser.email)"
                            />
                            <v-snackbar v-model="copyDialog" timeout="3000">
                                Copiat în clipboard!
                                <template v-slot:actions>
                                    <v-btn
                                        color="blue"
                                        variant="text"
                                        @click="copyDialog = false"
                                    >
                                        Închide
                                    </v-btn>
                                </template>
                            </v-snackbar>
                            <v-text-field
                                label="Password"
                                v-model="listUser.password"
                                variant="outlined"
                                hide-details
                                density="compact"
                                readonly
                                append-inner-icon="mdi-content-copy"
                                @click:append-inner="copyToClipboard(listUser.password)"
                            />
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-card class="mt-4">
                <v-toolbar>
                    <v-toolbar-title>
                        Listă clienți
                    </v-toolbar-title>
                    <v-toolbar-items>
                        <v-btn
                            color="success"
                            @click="addNewClient"
                        >
                            Adaugă client
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <div v-if="clientsList && clientsList.length > 0">
                        <v-row>
                            <v-col cols="1" class="d-flex justify-content-end align-items-center">
                                <th>
                                    Nr. crt.
                                </th>
                            </v-col>
                            <v-col cols="2" class="d-flex justify-content-center">
                                <th>Număr contract</th>
                            </v-col>
                            <v-col cols="2" class="d-flex justify-content-center">
                                <th>Prenume</th>
                            </v-col>
                            <v-col cols="2" class="d-flex justify-content-center">
                                <th>Nume</th>
                            </v-col>
                            <v-col cols="2" class="d-flex justify-content-center">
                                <th>
                                    Localitate
                                </th>
                            </v-col>
                            <v-col cols="2" class="d-flex justify-content-center">
                                <th>Status</th>
                            </v-col>
                            <v-col cols="1" class="d-flex justify-content-center">
                                <th>Acțiuni</th>
                            </v-col>
                        </v-row>
                        <v-row v-for="(client, index) in clientsList" :key="index"
                               :class="{'grey-background': hasFalsyValues(client) || focusedRow === index}"
                               @focusin="focusedRow = index"
                               @focusout="focusedRow = null">
                            <v-col cols="1" class="d-flex justify-content-end">
                                {{ index + 1 }}
                            </v-col>
                            <v-col cols="2" class="d-flex justify-content-end">
                                <v-text-field
                                    :reverse="true"
                                    density="compact"
                                    label="Nr. contract"
                                    variant="outlined"
                                    v-model="client.contract_number"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="2" class="d-flex justify-content-center">
                                <v-text-field
                                    density="compact"
                                    label="Prenume"
                                    variant="outlined"
                                    v-model="client.first_name"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="2" class="d-flex justify-content-center">
                                <v-text-field
                                    density="compact"
                                    label="Nume"
                                    variant="outlined"
                                    v-model="client.last_name"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="2" class="d-flex justify-content-center">
                                <v-text-field
                                    density="compact"
                                    label="Localitate"
                                    variant="outlined"
                                    v-model="client.city"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="2" class="d-flex justify-content-center">
                                <v-select
                                    density="compact"
                                    label="Status"
                                    variant="outlined"
                                    :items="availableStatusOptions"
                                    v-model="client.status"
                                    item-title="title"
                                    item-value="value"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="1" class="d-flex justify-content-center">
                                <v-btn
                                    color="red"
                                    density="compact"
                                    @click="removeClient(client.contract_number)"
                                    :disabled="clientsList.length === 1"
                                >
                                    Șterge
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-else class="mt-5 d-flex justify-content-center">
                        <h6>Nu există date disponibile.</h6>
                    </div>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
</template>

<script>
import {cloneDeep} from "lodash";
import apiClient from "@/utils/apiClient";
import {showAlertModal} from "@/utils/utils";

export default {
    name: "ClientsPrecontracts",
    data() {
        return {
            selectedRegion: 40,
            availableRegions: [],
            regionsWithClientsList: [],
            client: {
                first_name: '',
                last_name: '',
                city: '',
                status: 2,
                contract_number: null // New field for sorting
            },
            clientsList: [], // List of clients
            listUser: {
                email: '',
                password: ''
            }, // Credentials for the created client user
            availableStatusOptions: [
                {title: 'În așteptare', value: 1},
                {title: 'Finalizat', value: 2},
            ],
            copyDialog: false,
            sidebarLinks: [
                {
                    name: 'Clienți casă verde',
                    link: '#section1',
                    id: 'section1',
                }
            ],
            focusedRow: null,
        };
    },
    computed: {
        // Format and sort regions: regions with clients lists go first, others follow
        formattedRegions() {
            const regionsWithList = this.availableRegions
                .filter(region => this.regionsWithClientsList.includes(`${region.value}`))
                .map(region => ({
                    ...region,
                    icon: 'mdi-circle-small',
                }));

            const otherRegions = this.availableRegions
                .filter(region => !this.regionsWithClientsList.includes(`${region.value}`))
                .map(region => ({
                    ...region,
                    icon: '',
                }));

            // Concatenate the regions with a list and those without
            return [...regionsWithList, ...otherRegions];
        },
    },
    mounted() {
        this.$store.state.sidebarLinks = this.sidebarLinks;
        this.fetchRegions();
        this.fetchClientsList(); // Fetch list when component is mounted
    },
    methods: {
        hasFalsyValues(client) {
            return !client.contract_number || !client.first_name || !client.last_name || !client.city || !client.status;
        },
        async fetchRegions() {
            const userId = sessionStorage.getItem('userId');
            if (!userId) {
                alert("User not logged in!");
                return;
            }

            // Fetch all regions from the backend
            try {
                const citiesResponse = await fetch("/cities.json");
                const citiesData = await citiesResponse.json();

                this.availableRegions = citiesData
                    .filter(item => item.model === "cities_light.region")
                    .sort((a, b) => a.fields.display_name.localeCompare(b.fields.display_name))
                    .map(item => ({
                        title: item.fields.display_name,
                        value: item.pk,
                    }));

                // Fetch user's regions that have a GreenHouseClientsList
                const response = await apiClient.get(`/api/get_user_regions/?user_id=${userId}`);
                if (response && response.data) {
                    this.regionsWithClientsList = response.data.regions;
                }
            } catch (error) {
                console.error("Error fetching regions:", error);
            }
        },
        addNewClient() {
            this.clientsList.unshift(cloneDeep(this.client)); // Clone and add the new client
        },
        removeClient(contractNumber) {
            if (this.clientsList.length > 1) {
                // Find the index in the original clientsList by contract_number
                const clientIndex = this.clientsList.findIndex(client => client.contract_number === contractNumber);

                if (clientIndex !== -1) {
                    this.clientsList.splice(clientIndex, 1); // Remove the client from the original list
                }
            }
        },
        async saveClientsList() {
            const userId = sessionStorage.getItem('userId'); // Retrieve userId from sessionStorage

            if (!userId) {
                alert("User not logged in!");
                return;
            }

            // Check if there are no clients
            if (!this.clientsList || this.clientsList.length === 0) {
                showAlertModal(this.$store, 'Lista trebuie să conțină cel putin un client! Vă rugăm reîncercați!', 'danger', 12000);
                return;
            }

            // Sort the clientsList by contract_number before saving
            this.clientsList.sort((a, b) => a.contract_number - b.contract_number);

            try {
                const response = await apiClient.post("/api/save_clients_list/", {
                    user_id: userId,
                    region: this.selectedRegion,
                    clients: this.clientsList,
                });

                if (response.status === 200) {
                    this.clientsList = response.data.clients;
                    this.listUser.email = response.data.list_user.email;
                    this.listUser.password = response.data.list_user.password; // Save the password from the response
                    showAlertModal(this.$store, 'Lista a fost salvată cu succes!', 'success', 3000);
                }
            } catch (error) {
                console.error("Error saving clients list:", error);
                showAlertModal(this.$store, 'A apărut o eroare la salvarea listei. Vă rugăm reîncercați!', 'danger', 12000);
            }
        },
        async fetchClientsList() {
            const userId = sessionStorage.getItem('userId');
            if (!userId) {
                alert("User not logged in!");
                return;
            }

            if (!this.selectedRegion) {
                return;
            }

            try {
                const response = await apiClient.get(`/api/get_clients_list/?user_id=${userId}&region=${this.selectedRegion}`);

                if (response) {
                    this.clientsList = response.data.clients;

                    if (response.data.list_user) {
                        this.listUser.email = response.data.list_user.email;
                        this.listUser.password = response.data.list_user.password;
                    } else {
                        this.listUser = {email: '', password: ''};
                    }
                } else {
                    this.clientsList = [];
                }
            } catch (error) {
                console.error("An error occurred while fetching the list:", error);
            }
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text).then(() => {
                this.copyDialog = true;
            }).catch(err => {
                console.error('Failed to copy text: ', err);
            });
        },
    },
};
</script>

<style scoped>
.grey-background {
    background-color: #f0f0f0;
}
</style>
